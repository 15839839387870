import { OperationResponse, BillingProfile, Completer, CustomerAccount } from 'in-time-core';
import { DialogService } from '../core/components';
import { BillingDetails } from '../core/models/billing-details';
import { CancellationToken } from '../core/utils/cancellation-token';
import { logError, logInfo } from 'in-time-logger';
import { ErrorType } from '../core/models/error-type';

export async function showBillingDialog(
  dialogService: DialogService,
  cancellationToken: CancellationToken | null,
  options?: {
    allowBillingForLegalEntity?: boolean,
  }
): Promise<OperationResponse<BillingProfile>> {
  logInfo('Preparing to open the billing dialog...');

  const result = await import('./billing-dialog.component').then(({ BillingDialogComponent }) => {
    const completer = new Completer<OperationResponse<BillingProfile>>();
    const dialog = dialogService.open(BillingDialogComponent, {
      closable: false,
      data: {
        cancellationToken: cancellationToken,
        allowBillingForLegalEntity: options?.allowBillingForLegalEntity ?? false,
      },
    });

    dialog.onClose.subscribe((response) => completer.complete(response));
    return completer.promise;
  }).catch((error) => {
    logError(`Failed to load billing dialog: ${error}`);
    return OperationResponse.error<BillingProfile>(ErrorType.InternalAuthError);
  });

  logInfo('Billing details dialog has been closed.');
  return result;
}

export async function showSignInDialog(
  dialogService: DialogService,
  cancellationToken: CancellationToken | null,
  options?: {
    requiresExtendedBillingDetails?: boolean,
    allowBillingForLegalEntity?: boolean,
    allowGuestRegistration?: boolean,
  }
): Promise<{ account: CustomerAccount | null, billingDetails: BillingDetails | null }> {
  logInfo('Preparing to open the sign in dialog...');

  const result = await import('./sign-in-dialog.component').then(({ SignInDialogComponent }) => {
    const completer = new Completer<{ account: CustomerAccount | null, billingDetails: BillingDetails | null }>();
    const dialog = dialogService.open(SignInDialogComponent, {
      closable: false,
      data: {
        cancellationToken: cancellationToken,
        requiresExtendedBillingDetails: options?.requiresExtendedBillingDetails ?? false,
        allowBillingForLegalEntity: options?.allowBillingForLegalEntity ?? false,
        allowGuestRegistration: options?.allowGuestRegistration ?? false,
      },
    });

    dialog.onClose.subscribe((response) => completer.complete(response));
    return completer.promise;
  }).catch((error) => {
    logError(`Failed to load sign in dialog: ${error}`);
    return { account: null, billingDetails: null };
  });

  logInfo('Sign in dialog has been closed.');
  return result;
}